<i18n lang="json">
  {
    "ru": {
      "Подбор вклада": "Подбор вклада",
      "Подберем оптимальный вклад и поможем накопить на мечту": "Подберем оптимальный вклад и поможем накопить на мечту",
      "Сумма вклада": "Сумма вклада",
      "Срок вклада": "Срок вклада",
      "Я могу пополнять вклад": "Я могу пополнять вклад",
      "Подобрать": "Подобрать",
      "Вам подойдет": "Вам подойдет",
      "Вклад “Сохраняемый”": "Вклад “Сохраняемый”",
      "Вы получите:": "Вы получите:",
      "Чистый доход:": "Чистый доход:",
      "Открыть": "Открыть"
    },
    "en": {
      "Подбор вклада": "Deposit Selection",
      "Подберем оптимальный вклад и поможем накопить на мечту": "We will find the best deposit and help you save for your dream",
      "Сумма вклада": "Deposit Amount",
      "Срок вклада": "Deposit Term",
      "Я могу пополнять вклад": "I can top up the deposit",
      "Подобрать": "Select",
      "Вам подойдет": "Suitable for you",
      "Вклад “Сохраняемый”": "“Savings” Deposit",
      "Вы получите:": "You will receive:",
      "Чистый доход:": "Net income:",
      "Открыть": "Open"
    },
    "es": {
      "Подбор вклада": "Selección de Depósito",
      "Подберем оптимальный вклад и поможем накопить на мечту": "Encontraremos el mejor depósito y te ayudaremos a ahorrar para tu sueño",
      "Сумма вклада": "Monto del Depósito",
      "Срок вклада": "Plazo del Depósito",
      "Я могу пополнять вклад": "Puedo aumentar el depósito",
      "Подобрать": "Seleccionar",
      "Вам подойдет": "Adecuado para ti",
      "Вклад “Сохраняемый”": "Depósito “Ahorro”",
      "Вы получите:": "Recibirás:",
      "Чистый доход:": "Ingreso Neto:",
      "Открыть": "Abrir"
    },
    "ar": {
      "Подбор вклада": "اختيار الوديعة",
      "Подберем оптимальный вклад и поможем накопить на мечту": "سنعثر على أفضل وديعة وسنساعدك على الادخار لتحقيق حلمك",
      "Сумма вклада": "مبلغ الوديعة",
      "Срок вклада": "مدة الوديعة",
      "Я могу пополнять вклад": "يمكنني إضافة المزيد إلى الوديعة",
      "Подобрать": "اختيار",
      "Вам подойдет": "مناسب لك",
      "Вклад “Сохраняемый”": "وديعة “توفير”",
      "Вы получите:": "سوف تحصل على:",
      "Чистый доход:": "الدخل الصافي:",
      "Открыть": "فتح"
    },
    "tr": {
      "Подбор вклада": "Mevduat Seçimi",
      "Подберем оптимальный вклад и поможем накопить на мечту": "En iyi mevduatı bulacağız ve hayaliniz için tasarruf etmenize yardımcı olacağız",
      "Сумма вклада": "Mevduat Tutarı",
      "Срок вклада": "Mevduat Süresi",
      "Я могу пополнять вклад": "Mevduatı artırabilirim",
      "Подобрать": "Seç",
      "Вам подойдет": "Size Uygun",
      "Вклад “Сохраняемый”": "“Tasarruf” Mevduatı",
      "Вы получите:": "Alacaksınız:",
      "Чистый доход:": "Net gelir:",
      "Открыть": "Aç"
    }
  }
  </i18n>
  
  <template>
    <div
      class="px-4 lg:overflow-hidden lg:border-[1px] lg:px-9 lg:pt-9 border-[#E1E1E1] rounded-2xl relative h-auto lg:h-103 lg:pb-6"
    >
      <div class="relative lg:grid lg:grid-cols-11 gap-14">
        <div class="col-span-6">
          <p class="text-xl lg:text-3xl pb-0 lg:pb-[10px]">
            {{ t("Подбор вклада") }}
          </p>
          <p class="text-[#979797] lg:mt-0 mt-[10px] leading-none">
            {{ t("Подберем оптимальный вклад и поможем накопить на мечту") }}
          </p>
          <div class="lg:mt-7 mt-[14px] rounded-lg">
            <div>
              <div class="flex justify-between mb-3">
                {{ t("Сумма вклада") }}
                <div class="text-right">
                  {{ formatMoney(depositAmount) }}
                  <select
                    v-model="currency"
                    name="currency"
                    class="sum__currency focus:outline-none"
                  >
                    <option v-for="item in CURRENCIES" :key="item" :value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <input
                type="range"
                ref="rangeAmountSlider"
                v-model="depositAmount"
                :min="minDepositAmount"
                :max="maxDepositAmount"
                class="w-full"
              />
            </div>
            <div class="mt-4 lg:mt-8">
              <div class="flex justify-between mb-3">
                <p class="block text-gray-700">{{ t("Срок вклада") }}</p>
                {{ termOfDeposit }} мес.
              </div>
              <input
                type="range"
                id="term"
                ref="rangeMonthSlider"
                v-model="termOfDeposit"
                :min="minTermOfDeposit"
                :max="maxTermOfDeposit"
                class="w-full"
              />
            </div>
          </div>
          <AinputCheckbox
            class="mt-7"
            id="storageKey"
            v-model="isTopUpDeposit"
            :label="t('Я могу пополнять вклад')"
          >
          </AinputCheckbox>
  
          <AButton
            width="mobile"
            type="yellow"
            class="block lg:hidden !w-full mt-5 hover:border-2 hover:border-[#F9D849] hover:bg-white w-full"
            @click="openPopup()"
          >
            {{ t("Подобрать") }}
          </AButton>
  
          <div class="hidden lg:block">
            <AButton
              width="medium"
              class="hover:border-2 hover:border-[#F9D849] hover:bg-white mt-5"
              @click="openPopup()"
            >
              {{ t("Подобрать") }}
            </AButton>
          </div>
        </div>
        <div
          v-if="isPopupOpen"
          class="col-span-5 relative pt-6 lg:pt-0 flex flex-col justify-between"
        >
          <div>
            <p class="text-[#979797] font-firasans lg:text-3xl text-xl">
              {{ t("Вам подойдет") }}
            </p>
            <div class="grid gap-4 lg:gap-6">
              <p class=" lg:text-[26px] text-lg mt-[10px] lg:mt-10">
                {{
                  depositType == "Пополняемый"
                    ? `Вклад “Пополняемый”`
                    : t("Вклад “Сохраняемый”")
                }}
                <!-- {{ t("Вклад “Сохраняемый”") }} -->
              </p>
              <div
                class="text-base flex items-center flex-row space-x-8 font-sans"
              >
                <p class="text-sm md:text-base">
                  {{ t("Вы получите:") }}
                </p>
                <p class="text-base md:text-xl lg:text-3xl">
                  {{ formatMoney(totalDepositAmount) }} {{ t(currency) }}
                </p>
              </div>
              <div
                class="text-base flex items-center flex-row space-x-6 font-sans"
              >
                <p class="text-sm md:text-base">
                  {{ t("Чистый доход:") }}
                </p>
                <p class="text-base md:text-xl lg:text-3xl">
                  {{ formatMoney(interest) }} {{ t(currency) }}
                </p>
              </div>
            </div>
            <div
              class="bg-darkYellow w-[70px] h-[70px] items-center justify-center rounded-full absolute top-0 right-0 hidden lg:flex"
            >
              <div class="text-[26px] w-fit font-sans font-semibold">
                {{ interestRate * 100 }}%
              </div>
            </div>
          </div>
  
          <div>
            <nuxt-link :to="localePath('/deposits#deposits')">
              <AButton
                class="lg:hidden block !w-full mt-6 lg:mt-4"
                width="mobile"
                >{{ t("Открыть") }}
              </AButton></nuxt-link
            >
            <nuxt-link :to="localePath('/deposits#deposits')"
              ><AButton class="!w-full hidden lg:block"
                >{{ t("Открыть") }}
              </AButton></nuxt-link
            >
          </div>
        </div>
        <a-icon-depositor
          v-if="!isPopupOpen"
          class="mx-auto lg:absolute w-71 h-64 mt-6 lg:mt-0 lg:w-109 lg:h-97 -bottom-8 -right-4 xl:right-0"
        ></a-icon-depositor>
      </div>
    </div>
  </template>
  <script setup>
  const { t } = useI18n({ useScope: "local" });
  const MIN_RUB_AMOUNT = 50000;
  const MAX_RUB_AMOUNT = 100000000;
  const MIN_USD_AMOUNT = 1000;
  const MAX_USD_AMOUNT = 100000;
  const MIN_EUR_AMOUNT = 1000;
  const MAX_EUR_AMOUNT = 100000;
  
  const MIN_TERM_OF_DEPOSIT = 3;
  const MAX_TERM_OF_DEPOSIT = 36;
  const DEFAULT_TERM_OF_DEPOSIT = 12;
  
  const CURRENCIES = reactive(["RUB", "EUR", "USD"]);
  
  const AVAILABLE_TYPES = reactive({
    RUB: ["Сохраняемый", "Пополняемый"],
    EUR: ["Сохраняемый"],
    USD: ["Сохраняемый"],
  });
  
  const INETEREST_RATE = {
    RUB: {
      Сохраняемый: { 3: 6, 6: 8, 12: 10 },
      Пополняемый: { 3: 4, 6: 6, 12: 8 },
    },
    EUR: {
      Сохраняемый: { 3: 1, 6: 2, 12: 3 },
    },
    USD: {
      Сохраняемый: { 3: 1, 6: 2, 12: 3 },
    },
  };
  
  const props = defineProps({
    depositType: {
      type: String,
    },
    termOfDeposit: {
      type: Number,
    },
    depositAmount: {
      type: Number,
    },
    currency: {
      type: String,
    },
  });
  const emit = defineEmits([
    "update:currency",
    "update:depositType",
    "update:depositAmount",
    "update:termOfDeposit",
  ]);
  
  const depositType = computed({
    get() {
      return !!props.depositType
        ? props.depositType
        : AVAILABLE_TYPES[CURRENCIES[0]][0];
    },
    set(value) {
      emit("update:depositType", value);
    },
  });
  
  const depositAmount = computed({
    get() {
      return !!props.depositAmount ? props.depositAmount : MIN_RUB_AMOUNT;
    },
    set(value) {
      emit("update:depositAmount", value);
    },
  });
  
  const termOfDeposit = computed({
    get() {
      return !!props.termOfDeposit
        ? props.termOfDeposit
        : DEFAULT_TERM_OF_DEPOSIT;
    },
    set(value) {
      emit("update:termOfDeposit", value);
    },
  });
  
  const currency = computed({
    get() {
      return !!props.currency ? props.currency : CURRENCIES[0];
    },
    set(value) {
      emit("update:currency", value);
    },
  });
  
  emit("update:currency", currency.value);
  emit("update:depositType", depositType.value);
  emit("update:termOfDeposit", termOfDeposit.value);
  emit("update:depositAmount", depositAmount.value);
  
  const isTopUpDeposit = ref(false);
  const rangeAmountSlider = ref(null);
  const rangeMonthSlider = ref(null);
  
  watch(depositAmount, (newValue) => {
    const value =
      ((newValue - rangeAmountSlider.value.min) /
        (rangeAmountSlider.value.max - rangeAmountSlider.value.min)) *
      100;
    rangeAmountSlider.value.style.background = `linear-gradient(to right, #FFCA00 ${value}%, #BFBFBF ${value}%)`;
  });
  
  watch(termOfDeposit, (newValue) => {
    const value =
      ((newValue - rangeMonthSlider.value.min) /
        (rangeMonthSlider.value.max - rangeMonthSlider.value.min)) *
      100;
    rangeMonthSlider.value.style.background = `linear-gradient(to right, #FFCA00 ${value}%, #BFBFBF ${value}%)`;
  });
  
  onMounted(() => {
    const val1 =
      ((depositAmount.value - rangeAmountSlider.value.min) /
        (rangeAmountSlider.value.max - rangeAmountSlider.value.min)) *
      100;
    rangeAmountSlider.value.style.background = `linear-gradient(to right, #FFCA00 ${val1}%, #BFBFBF ${val1}%)`;
  
    const val2 =
      ((termOfDeposit.value - rangeMonthSlider.value.min) /
        (rangeMonthSlider.value.max - rangeMonthSlider.value.min)) *
      100;
    rangeMonthSlider.value.style.background = `linear-gradient(to right, #FFCA00 ${val2}%, #BFBFBF ${val2}%)`;
  });
  
  watch(isTopUpDeposit, () => {
    if (isTopUpDeposit.value) {
      switch (currency.value) {
        case "RUB":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[0]][1];
          break;
        case "EUR":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[1]][0];
          break;
        case "USD":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[2]][0];
          break;
      }
    } else {
      switch (currency.value) {
        case "RUB":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[0]][0];
          break;
        case "EUR":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[1]][0];
          break;
        case "USD":
          depositType.value = AVAILABLE_TYPES[CURRENCIES[2]][0];
          break;
      }
    }
  });
  
  const isShowCheck = computed(() => {
    let rate = AVAILABLE_TYPES[currency.value][1];
    if (!!rate) {
      return true;
    }
    return false;
  });
  
  const minDepositAmount = ref(MIN_RUB_AMOUNT);
  const maxDepositAmount = ref(MAX_RUB_AMOUNT);
  const minTermOfDeposit = ref(MIN_TERM_OF_DEPOSIT);
  const maxTermOfDeposit = ref(MAX_TERM_OF_DEPOSIT);
  const depositAmountSlider = ref(null);
  const termOfDepositSlider = ref(null);
  const isPopupOpen = ref(false);
  
  const interestRate = computed(() => {
    const availableTypes = INETEREST_RATE[currency.value][depositType.value];
    const months = Object.keys(availableTypes)
      .map((key) => Number(key))
      .reverse();
    const month = months.find((key) => {
      return key <= termOfDeposit.value;
    });
    return INETEREST_RATE[currency.value][depositType.value][month] / 100;
  });
  
  const interest = computed(() => {
    return depositAmount.value * interestRate.value * (termOfDeposit.value / 12);
  });
  
  const totalDepositAmount = computed(() => {
    return Number(depositAmount.value) + Number(interest.value);
  });
  
  watch(currency, () => {
    depositType.value = AVAILABLE_TYPES[currency.value][0];
    termOfDeposit.value = DEFAULT_TERM_OF_DEPOSIT;
    switch (currency.value) {
      case "RUB":
        depositAmount.value = MIN_RUB_AMOUNT;
        minDepositAmount.value = MIN_RUB_AMOUNT;
        maxDepositAmount.value = MAX_RUB_AMOUNT;
        // if(isTopUpDeposit.value) {
        //   depositType.value = AVAILABLE_TYPES[currency.value][1];
        // }
        break;
      case "EUR":
        depositAmount.value = MIN_EUR_AMOUNT;
        minDepositAmount.value = MIN_EUR_AMOUNT;
        maxDepositAmount.value = MAX_EUR_AMOUNT;
        isTopUpDeposit.value = false;
        break;
      case "USD":
        depositAmount.value = MIN_USD_AMOUNT;
        minDepositAmount.value = MIN_USD_AMOUNT;
        maxDepositAmount.value = MAX_USD_AMOUNT;
        isTopUpDeposit.value = false;
        break;
    }
  });
  
  function formatMoney(number) {
    return new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: 0,
    }).format(number);
  }
  
  function openPopup() {
    isPopupOpen.value = true;
  }
  </script>
  
  <style scoped>
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
  }
  
  input[type="radio"]:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FFCA00;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    /* Высота трека */
    background: linear-gradient(to right, #FFCA00 0%, #bfbfbf 0%);
    /* Цвет трека */
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  input[type="range"]:hover {
    opacity: 1;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    /* Ширина ползунка */
    height: 25px;
    /* Высота ползунка */
    background: #FFCA00;
    /* Цвет ползунка */
    cursor: pointer;
    /* Изменение курсора при наведении */
    border-radius: 50%;
    /* Круглая форма ползунка */
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 25px;
    /* Ширина ползунка */
    height: 25px;
    /* Высота ползунка */
    background: #FFCA00;
    /* Цвет ползунка */
    cursor: pointer;
    /* Изменение курсора при наведении */
    border-radius: 50%;
    /* Круглая форма ползунка */
  }
  </style>
  